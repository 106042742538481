const AboutUs: React.FC = () => {
    return <>
        <div className="container">
            <div className="row pb-0">
                <div className="col-12 col-md-12 row pt-5">
                    <div className="col-12 col-md-6 ">
                        <div className="responsive-div text-dark" style={{ height: "60vh" }}>
                            <img src="AboutUs.png" alt="Example Image" className="img-fluid" style={{ height: "inherit" }} />
                        </div>
                    </div>
                    <div className="col-12 col-md-6 child-2-main-container pt-4">
                        <div className="responsive-div banner text-white p-4">
                            <h2>Why Choose Us?</h2>
                            {/* <p>Property Management-A way to manage your property.</p> */}
                            <div className='pt-3'></div>
                            <ul>
                                <li><strong>Affordable Convenience Fee:</strong> Just 25 days' rent for 11 months of management. No extra charges for tenant changes!</li>
                                <li><strong>Unlimited Property Visits:</strong> Enjoy peace of mind with unlimited visits available to you anytime!</li>
                                <li><strong>Expert Tenant Search Assistance:</strong> We connect you with the right tenants quickly and efficiently.</li>
                                <li><strong>Scheduled Quarterly Inspections:</strong> Keep your property in top shape with inspections available upon request!</li>
                            </ul>
                        </div>
                        {/* <div className="responsive-div banner text-white p-4">
                            <p>Property management harder than you thought ?</p>
                            <ul>
                                <li>90% of house owners face problems finding a new tenant.</li>
                                <li>75% of house owners find it hard to maintain their property.</li>
                                <li>60% of house owners are unhappy because of property damage.</li>
                                <li>55% of house owners struggle to find reliable renovation & interior vendors.</li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default AboutUs