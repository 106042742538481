import './Homepage.scss'
import logo from '../../Images/logo.png'
import Services from '../Services/Services'
import { useState } from 'react'
import AboutUs from '../AboutUs/AboutUs'
import ContactUs from '../ContactUs/ContactUs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareWhatsapp } from '@fortawesome/free-brands-svg-icons'
const HomePage: React.FC = () => {

    const [activepage, setActivepage] = useState<number>(0)
    return <>
        <div className='main-container'>
            <div className="col-12 col-md-12 navbar-sticky">
                <nav className="navbar navbar-expand-lg">
                    <p className="navbar-brand" style={{ color: '#1f2e59', fontWeight: "bold", cursor: "pointer" }} onClick={() => { setActivepage(0) }}>NEO FLEET REALCON</p>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <p className="nav-link" style={{ color: '#1f2e59', fontWeight: "bold", cursor: "pointer", margin: 0 }} onClick={() => { setActivepage(1) }}>Services</p>
                            </li>
                            <li className="nav-item">
                                <p className="nav-link" style={{ color: '#1f2e59', fontWeight: "bold", cursor: "pointer", margin: 0 }} onClick={() => { setActivepage(2) }}>About</p>
                            </li>
                            <li className="nav-item">
                                <p className="nav-link" style={{ color: '#1f2e59', fontWeight: "bold", cursor: "pointer", margin: 0 }} onClick={() => { setActivepage(3) }}>Contact us</p>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
            {activepage === 0 && (
                <div className="container">
                    <div className="row pb-0">
                        <div className="col-12 col-md-12 row">
                            <div className="col-12 col-md-6 ">
                                <div className="responsive-div text-dark" style={{ height: "100vh", textAlign: "center" }}>
                                    <img src={logo} alt="Example Image" className="img-fluid" style={{ height: "inherit" }} />
                                </div>
                            </div>
                            <div className="col-12 col-md-6 child-2-main-container pt-3">
                                <div className="responsive-div banner text-white p-4">
                                    <h2>NEO FLEET REALCON</h2>
                                    <p>Property Management-A way to manage your property.</p>
                                    <div className='line-break'></div>
                                    <p className='pt-5 content'>
                                        Are you tired of the hassle of managing your property?   <br />
                                        Look no further! Our comprehensive property
                                        management services are designed to make your life
                                        easier and your investments more profitable.
                                    </p>
                                </div>
                                <div className="responsive-div banner text-white p-4">
                                    <p>Property management harder than you thought ?</p>
                                    <ul>
                                        <li>90% of house owners face problems finding a new tenant.</li>
                                        <li>75% of house owners find it hard to maintain their property.</li>
                                        <li>60% of house owners are unhappy because of property damage.</li>
                                        <li>55% of house owners struggle to find reliable renovation & interior vendors.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {activepage === 1 && (<Services />)}
            {activepage === 2 && (<AboutUs />)}
            {activepage === 3 && (<ContactUs />)}
        </div>
        <div style={{ position: "sticky", zIndex: 9999, textAlign: "right", bottom: 0 }}>
            <FontAwesomeIcon icon={faSquareWhatsapp} style={{ color: "#00e646", }} size="3x" />
        </div>
    </>
}
export default HomePage