import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional Theme applied to the Data Grid
import './Service.scss'
import logo from '../../Images/logo.png'
const Services: React.FC = () => {
    const arr = Array.from({ length: 12 });
    const painting_charges = [
        { Type: "OBD-Asian", onebhk: 7500, Twobhk: 12000, Threebhk: 17000 },
        { Type: "Tractor Emulson-Asian", onebhk: 16000, Twobhk: 19000, Threebhk: 24000 },
        { Type: "Premium Emulson-Asian", onebhk: 28667, Twobhk: 32000, Threebhk: 42000 },
        { Type: "Royal Emulson-Asian", onebhk: 39000, Twobhk: 55000, Threebhk: 70000 },
    ];
    const columnDefs: any[] = [
        { field: 'Type', headerName: "Type", maxWidth: 500, cellClass: 'custom-text-color' },
        { field: 'onebhk', headerName: "1 bhk", maxWidth: 100, cellClass: 'custom-text-color' },
        { field: 'Twobhk', headerName: "2 bhk", maxWidth: 100, cellClass: 'custom-text-color' },
        { field: 'Threebhk', headerName: "3 bhk", maxWidth: 100, cellClass: 'custom-text-color' }
    ]
    return <>
        <div>
            <Carousel
                autoPlay={true}
                infiniteLoop={true}
                interval={3000}
                dynamicHeight={false}
                showThumbs={false}
                stopOnHover={false}
                showArrows={false}
                showStatus={false}
            >
                {arr.map((e, i) => {
                    return (
                        <div>
                            <img src={`carousel/img_${i + 1}.png`} alt="carousel image" style={{ maxHeight: "400px" }} />
                        </div>
                    );
                })}
            </Carousel>
        </div>
        <div className="container">
            <div className="row pb-0">
                <div className="col-12 col-md-12 row pb-4">
                    <div className="col-12 col-md-6 child-2-main-container pt-3">
                        <div className="responsive-div banner text-white p-4">
                            <section className="additional-services content">
                                <h2>Additional Services</h2>
                                <ul>
                                    <li><strong>Comprehensive Move-In Cleaning:</strong> We include mopping and brooming to ensure your space is spotless!</li>
                                    <li><strong>Seamless Move-In/Out Assistance:</strong> Receive verified inspection reports for hassle-free transitions.</li>
                                    <li><strong>Hassle-Free Rent Agreements & Police Verification:</strong> We handle all the paperwork for you!</li>
                                    <li><strong>Affordable Renovation & Interior Work:</strong> Enhance your property with budget-friendly options.</li>
                                    <li><strong>Professional House Repainting:</strong> Refresh your home with our reasonable rates.</li>
                                </ul>
                            </section>
                        </div>
                        {/* <div className="responsive-div banner text-white p-4">
                            <p>Property management harder than you thought ?</p>
                            <ul>
                                <li>90% of house owners face problems finding a new tenant.</li>
                                <li>75% of house owners find it hard to maintain their property.</li>
                                <li>60% of house owners are unhappy because of property damage.</li>
                                <li>55% of house owners struggle to find reliable renovation & interior vendors.</li>
                            </ul>
                        </div> */}
                    </div>
                    <div className="col-12 col-md-6 child-2-main-container pt-3">
                        <div className="responsive-div banner text-white p-4">
                            <section className="buy-sell-assistance content">
                                <h2>Buy/Sell Assistance</h2>
                                <ul>
                                    <li><strong>Buy/Sell Assistance at Just 0.5%:</strong> Smooth transactions for buying or selling your property.</li>
                                    <li><strong>Efficient Society Coordination:</strong> We handle society-related tasks on your behalf.</li>
                                    <li><strong>Complete House Furnishing Solutions:</strong> Fully furnish your property for immediate occupancy.</li>
                                    <li><strong>Packers and Movers Services:</strong> Moving doesn
                                        't have to be stressful. Our professional packers
                                        and movers services are designed to handle every aspect of
                                        relocation with care and precision, ensuring a smooth transition
                                        for both property owners and tenants.</li>
                                </ul>
                            </section>
                        </div>
                    </div>
                </div>
                <div className='service-line-break'></div>
                <div className="col-12 col-md-12 row pt-4 pb-4">
                    <div className="col-12 p-5 custom-text-color" style={{ fontSize: "28px", fontWeight: "bold" }}> Painting charges :</div>
                    <div className="col-lg-6 col-sm-12  pt-3 ag-theme-alpine justify-content-center" style={{ height: 270, margin: "auto", paddingBottom:"20px" }}>
                        <AgGridReact
                            columnDefs={columnDefs}
                            defaultColDef={{ sortable: false }}
                            rowData={painting_charges}
                        />
                    </div>

                </div>
                <div className='service-line-break'></div>
                <div className="col-12 col-md-12 row pt-4 mt-3">
                    <div className="col-12 col-md-6 ">
                        <div className="responsive-div text-dark" style={{ height: "50vh",textAlign:"center" }}>
                            <img src="serviceagreement_logo.png" alt="Example Image" className="img-fluid" style={{ height: "inherit" }} />
                        </div>
                    </div>
                    <div className="col-11 col-md-6 child-2-main-container pt-3"style={{textAlign:"center"}}>
                        <div className="responsive-div banner text-white p-4">
                            <section className="buy-sell-assistance content">
                                <h2>Service Agreement renewal charges</h2>
                                Renewing your service agreement
                                with us is simple and affordable.
                                For continued property
                                management support, we charge
                                only 7 days
                                '
                                rent of your property
                                as the renewal fee, ensuring you
                                maintain access to all our
                                premium services without any
                                hassle
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default Services