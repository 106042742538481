import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faEnvelopeOpen,faPhone } from '@fortawesome/free-solid-svg-icons';

const ContactUs: React.FC = () => {
    return <>
        <div className="container">
            <div className="row pb-0">
                <div className="col-12 col-md-12 row pt-5">
                    <div className="col-12 col-md-6 ">
                        <div className="responsive-div text-dark" style={{ height: "60vh" }}>
                            <img src="ContactUs.png" alt="Example Image" className="img-fluid" style={{ height: "inherit" }} />
                        </div>
                    </div>
                    <div className="col-12 col-md-6 child-2-main-container pt-3">
                        <div className="responsive-div banner text-white p-4">
                            <p style={{ fontSize: "30px" }}>Thanking You !!</p>
                            <div className='pt-3'></div>
                            <p>Call or Whatsapp for more details</p>
                            <div className="row" style={{ justifyContent: "space-evenly" }}>
                            <div style={{ width: "20%", textAlign: "center" }}>
                                    {/* <img src="call_icon.png" alt="Example Image" className="img-fluid" style={{ width: "200px" }} /> */}
                                    <FontAwesomeIcon icon={faPhone} color="#1f2e59" size="2x" />
                                </div>
                                <div style={{ display: "flex", alignItems: "center",width:'70%' }}>
                                    <p style={{ fontSize: "20px", color: "#1f2e59",textAlign:"left" }}>9452441498, 7489009145</p>
                                </div>
                            </div>
                            <div className="row" style={{ justifyContent: "space-evenly" }}>
                                <div  style={{ width: "20%", textAlign: "center" }}>
                                    <FontAwesomeIcon icon={faEnvelopeOpen} color="#1f2e59" size="2x" />
                                </div>
                                <div style={{ display: "flex", alignItems: "center",width:"70%" }}>
                                    <p style={{ fontSize: "20px", color: "#1f2e59",textAlign:"left" }}>info@neofleetreal.com</p>
                                </div>
                            </div>
                            <div className="row" style={{ justifyContent: "space-evenly" }}>
                                <div style={{ width: "20%", textAlign: "center" }}>
                                    <FontAwesomeIcon icon={faLocationDot} color="#1f2e59" size="2x" />
                                </div>
                                <div style={{ display: "flex", alignItems: "center", width: "70%" }}>
                                    <p style={{ fontSize: "20px", color: "#1f2e59" }}>Office Address: 1526 Gaur City
                                        Mall Noida Extention</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default ContactUs